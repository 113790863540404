<template>
  <div class="register">
    <header>
      <cc-logo-header type="reset"/>
    </header>
    <div class="content">
      <div class="form-wrapper">
        <div class="title">
          <a-tabs v-model="accountType" @click="handleAccountTypeChange">
            <a-tab-pane :key="ACCOUNT_TYPE.PRIMARY_ACCOUNT" :tab="$t('zhu-zhang-hao')">
            </a-tab-pane>
            <a-tab-pane :key="ACCOUNT_TYPE.SUB_ACCOUNT" :tab="$t('zi-zhang-hao')"></a-tab-pane>
          </a-tabs>
        </div>
        <div class="form">
          <a-form-model ref="resetFormRef" :label-col="labelCol" :model="resetForm" :rules="resetRuleValidate"
                        :wrapper-col="wrapperCol">
            <a-form-model-item v-if="errorMsg" prop="subAccount">
              <a-alert show-icon type="error">{{ errorMsg }}</a-alert>
            </a-form-model-item>
            <a-form-model-item
              v-for="(item,i) in Object.keys(accountType === ACCOUNT_TYPE.PRIMARY_ACCOUNT ? resetForm : {subAccount: '', ...resetForm})"
              :key="item"
              :label="resetFormLabel[item]" :prop="item">
              <a-input v-model="resetForm[item]" :placeholder="$t('qing-shu-ru-registerformlabelitem', [resetFormLabel[item]])"
                       :style="{width: `${item === 'verifyCode' ? 200 : 330}px`,marginRight:'8px'}"
                       :type="item === 'password' || item === 'rePassword' ? 'password' : 'text'"
                       autocomplete="new-password" size="large" @pressEnter="handleEnter(i)"/>
              <cc-sms-button v-if="item==='verifyCode'" :phoneNumber="resetForm.phone"
                             :verifyCodeType="VERIFY_CODE_TYPE.RESET_PASSWORD" :verifyType="VERIFY_TYPE.SMS"
                             size="large" style="width: 120px" :account="resetForm.subAccount" :sub="accountType===ACCOUNT_TYPE.SUB_ACCOUNT"/>
            </a-form-model-item>
            <a-form-model-item label=" ">
              <a-button :disabled="resetLoading" class="reset-btn" size="large" style="width: 330px" type="primary"
                        @click.native="handleSubmit">{{ $t('que-ding') }}
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <footer>
      <cc-footer/>
    </footer>
  </div>
</template>

<script>
import CCLogoHeader from '@/components/CCLogoHeader';
import CCFooter from '@/components/CCFooter';
import { ACCOUNT_TYPE, VERIFY_CODE_TYPE, VERIFY_TYPE } from '@/const';

export default {
  name: 'ResetPwd',
  components: {
    CcFooter: CCFooter,
    CcLogoHeader: CCLogoHeader
  },
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('qing-zai-ci-shu-ru-ni-de-mi-ma')));
      } else if (value !== this.resetForm.password) {
        callback(new Error(this.$t('liang-ci-mi-ma-bu-yi-zhi')));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      VERIFY_CODE_TYPE,
      VERIFY_TYPE,
      ACCOUNT_TYPE,
      resetLoading: false,
      timer: null,
      errorMsg: '',
      accountType: ACCOUNT_TYPE.PRIMARY_ACCOUNT,
      resetForm: {
        phone: '',
        verifyCode: '',
        password: '',
        rePassword: ''
      },
      resetFormLabel: {
        subAccount: this.$t('zhang-hao'),
        phone: this.$t('shou-ji-hao-ma'),
        verifyCode: this.$t('duan-xin-yan-zheng-ma'),
        password: this.$t('mi-ma'),
        rePassword: this.$t('que-ren-mi-ma')
      },
      resetRuleValidate: {
        subAccount: [
          {
            required: true,
            message: this.$t('zhang-hao-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t('shou-ji-hao-bu-neng-wei-kong')
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: this.$t('shou-ji-hao-ma-ge-shi-bu-zheng-que'),
            trigger: 'blur'
          }
        ],
        verifyCode: [
          {
            required: true,
            message: this.$t('yan-zheng-ma-bu-neng-wei-kong')
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('mi-ma-bu-neng-wei-kong')
          },
          {
            min: 8,
            message: this.$t('mi-ma-chang-du-wei-832')
          },
          {
            max: 32,
            message: this.$t('mi-ma-chang-du-wei-832')
          }
        ],
        rePassword: [
          {
            required: true,
            message: this.$t('que-ren-mi-ma-bu-neng-wei-kong')
          },
          {
            validator: validatePassCheck
            // trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    handleAccountTypeChange() {
      this.resetForm = {
        phone: '',
        verifyCode: '',
        password: '',
        rePassword: ''
      };
      this.$refs.resetFormRef.resetFields();
    },
    async handleSubmit() {
      this.$refs.resetFormRef.validate(async (valid) => {
        if (valid) {
          this.resetLoading = true;
          const data = {
            accountType: this.accountType,
            phoneAreaCode: 'CHINA',
            verifyType: 'SMS_VERIFY_CODE',
            ...this.resetForm
          };
          delete data.rePassword;
          const res = await this.$services.resetPwdUnLogin({ data });
          if (res.success) {
            await this.$router.push({ name: 'Login' });
          }
        }

        this.resetLoading = false;
      });
    },
    handleEnter(i) {
      if (i === Object.keys(this.resetForm).length - 1) {
        this.handleSubmit();
      }
    }
  }
};
</script>

<style lang="less">
.register {
  background-color: #EDEDED;

  .ant-form .ant-form-item {
    margin-bottom: 20px;

    .ant-form-explain {
      bottom: -20px;
    }
  }

  /deep/ .ant-tabs {
    .ant-tabs-tab {
      font-size: 14px;

      &:hover {
        color: #333333;
      }
    }

    .ant-tabs-tab-active {
      color: #333333;
    }
  }

  .content {
    position: absolute;
    top: 80px;
    bottom: 50px;
    background-color: #EDEDED;
    .width-full();
    display: flex;
    justify-content: center;

    .form-wrapper {
      background: white;
      overflow: scroll;
      min-width: 1000px;
      max-width: 1000px;
      position: relative;
      padding-top: 45px;

      .title {
        color: #333;
        text-align: center;
        font-size: 20px;

        .ivu-tabs-nav-scroll {
          display: flex;
          justify-content: center;
        }

        .ivu-tabs-tab {
          height: 85px;
          line-height: 85px;
        }
      }

      .form {
        margin-top: 25px;
        margin-left: 240px;

        .ivu-form-item-content {
          display: flex;

          .ivu-form-item-error-tip {
            position: relative;
            padding-top: 0;
            line-height: 40px;
            margin-left: 10px;
          }
        }
      }
    }

    .reset-btn {
      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold;
        font-weight: 500;
      }
    }
  }

  footer {
    .width-full();
    background-color: #EDEDED;
    position: absolute;
    bottom: 0;
  }
}
</style>
